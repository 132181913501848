import React from "react";
import SEO from "../components/SEO";
import HeroSection from "@src/components/partials/press/HeroSection";
import BrandLoveSection from "@src/components/partials/about/BrandLoveSection";
import CoverageSection from "@src/components/partials/press/CoverageSection";
import NewsSection from "@src/components/partials/press/NewsSection";
import PressReleasesSection from "@src/components/partials/press/PressReleasesSection";
import CTASection from "@src/components/partials/press/CTASection";
import { graphql, useStaticQuery } from "gatsby";
import { defaultNormalizeStaticQueryResult } from "@src/utils/normalize-static-query-result";

const PressPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      directus {
        page_press_v2 {
          news_icon {
            id
            filename_disk
          }
          article_button_url
          contact_button_url
          press_kit_button_url
          brand_love_title
          brand_love_text
          clippings {
            id
            news_image {
              id
              filename_disk
            }
            news_image_alt
            news_headline
            news_text
            news_url
          }
          coverage_space_image {
            id
            filename_disk
          }
          coverage_items
          press_releases
          cta_image {
            id
            filename_disk
          }
        }
        page_careers {
          brand_love_images
          brand_love_tweets
        }
      }
    }
  `);

  defaultNormalizeStaticQueryResult(data);

  const {
    directus: {
      page_press_v2: {
        news_icon,
        article_button_url,
        contact_button_url,
        press_kit_button_url,
        brand_love_title,
        brand_love_text,
        clippings,
        coverage_space_image,
        coverage_items,
        press_releases,
        cta_image,
      },
      page_careers: { brand_love_images, brand_love_tweets },
    },
  } = data;

  const HeroSectionProps = {
    hero_text: "directus.page_press_v2.hero_text",
    contact_button_text: "directus.page_press_v2.contact_button_text",
    press_kit_button_text: "directus.page_press_v2.press_kit_button_text",
    news_icon: news_icon.file.publicURL,
    article_button_text: "directus.page_press_v2.article_button_text",
    article_button_url,
    contact_button_url,
    press_kit_button_url,
  };

  const BrandLoveSectionProps = {
    brand_love_title,
    brand_love_text,
    brand_love_images,
    brand_love_tweets,
    brand_love_cta_text: "directus.page_careers.brand_love_cta_text",
    BGColor: false,
    three_liner: false,
  };

  const CoverageSectionProps = {
    coverage_space_image,
    coverage_items_data: coverage_items.map((item, idx) => ({
      text: `directus.page_press_v2.coverage_items.text_${idx + 1}`,
    })),
  };

  const NewsSectionProps = {
    clippings,
    news_title: "directus.page_press_v2.news_title",
  };

  const PressReleasesProps = {
    press_releases_title: "directus.page_press_v2.press_releases_title",
    press_releases: press_releases.map((release, idx) => ({
      date: `directus.page_press_v2.press_releases.date_${idx + 1}`,
      headline: `directus.page_press_v2.press_releases.headline_${idx + 1}`,
      url: release.url,
    })),
    read_more_text: "directus.page_press_v2.read_more_text",
  };

  const CTASectionProps = {
    cta_text: "directus.page_press_v2.cta_text",
    cta_contact_button_text: "directus.page_press_v2.cta_contact_button_text",
    cta_kit_button_text: "directus.page_press_v2.cta_kit_button_text",
    cta_image: cta_image.file.publicURL,
    contact_button_url,
    press_kit_button_url,
  };

  return (
    <>
      <SEO title="page_titles.press" description="page_descriptions.press" />
      <HeroSection {...HeroSectionProps} />
      <BrandLoveSection {...BrandLoveSectionProps} />
      <CoverageSection {...CoverageSectionProps} />
      <NewsSection {...NewsSectionProps} />
      <PressReleasesSection {...PressReleasesProps} />
      <CTASection {...CTASectionProps} />
    </>
  );
};
export default PressPage;
